import React from 'react';
import './AlbumPlaqueSecondary.scss';

export default function AlbumPlaqueSecondary({ type, albumName, albumArtists, albumImage }) {
  const secClassName = `sec-text-container-${type}`;

  return (
    <div className={secClassName}>
      <div className="sec-plaque-frosted-glass">
        <div className="sec-album-cover-container">
          <img src={albumImage} alt="album-cover"/>
        </div>
        <div className="sec-album-info">
          <div className="sec-album-name-text">{albumName}</div>
          <div className="sec-album-artist-text">{albumArtists}</div>
        </div>
      </div>
    </div>
  );
}
