import React from 'react';
import './AlbumDisplay.css';
import AlbumPlaque from "../AlbumPlaque/AlbumPlaque";
import AlbumPlaqueSecondary from "../AlbumPlaque/AlbumPlaqueSecondary";

export default function AlbumDisplay(props) {
    const previousAlbum = props.previousAlbum;
    const album = props.album;
    const nextAlbum = props.nextAlbum;
    const changeToPreviousAlbum = props.changeToPreviousAlbum;
    const changeToNextAlbum = props.changeToNextAlbum;
    const isPreviousDisabled = props.isPreviousDisabled;
    const isNextDisabled = props.isNextDisabled;

    return (
        <div className="album-display">
            <button onClick={changeToPreviousAlbum} disabled={isPreviousDisabled}>{"<"}</button>
            {
                previousAlbum !== null 
                    ?
                    <AlbumPlaqueSecondary
                        type="previous"
                        albumName={previousAlbum.albumName}
                        albumArtists={previousAlbum.albumArtists}
                        albumImage={previousAlbum.albumImage}
                    />
                    :
                    <></>
            }
            <AlbumPlaque
                albumName={album.albumName}
                albumArtists={album.albumArtists}
                albumImage={album.albumImage}
            />
            {
                nextAlbum !== null 
                    ?
                    <AlbumPlaqueSecondary
                        type="next"
                        albumName={nextAlbum.albumName}
                        albumArtists={nextAlbum.albumArtists}
                        albumImage={nextAlbum.albumImage}
                    />
                    :
                    <></>
            }
            
            <button onClick={changeToNextAlbum} disabled={isNextDisabled}>{">"}</button>
        </div>
    );
}
