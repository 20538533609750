import React from 'react';
import './AlbumPlaque.css';

export default function AlbumPlaque({ albumName, albumArtists, albumImage }) {
  return (
    <div className="text-container">
      <div className="plaque-frosted-glass">
        <div className="album-cover-container">
          <img src={albumImage} alt="album-cover"/>
        </div>
        <div className="album-info">
          <div className="album-name-text">{albumName}</div>
          <div className="album-artist-text">{albumArtists}</div>
        </div>
      </div>
    </div>
  );
}
