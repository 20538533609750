import React, { useEffect, useState } from "react";
import "./App.scss";
import AlbumDisplay from "./Components/AlbumDisplay/AlbumDisplay";

const albums = [
  {
    albumName: "To Pimp a Butterfly",
    albumArtists: "Kendrick Lamar",
    albumImage: "https://i.scdn.co/image/ab67616d0000b273cdb645498cd3d8a2db4d05e1"
  },
  {
    albumName: "The Life of Pablo",
    albumArtists: "Kanye West",
    albumImage: "https://i.scdn.co/image/ab67616d0000b2732a7db835b912dc5014bd37f4"
  },
  {
    albumName: "Enter the Wu-Tang (36 Chambers)",
    albumArtists: "Wu-Tang Clan",
    albumImage: "https://i.scdn.co/image/ab67616d00001e020f0b0557b1583e219357783c"
  },
  {
    albumName: "Nada Como Um Dia Após o Outro Dia",
    albumArtists: "Racionais MC's",
    albumImage: "https://i.scdn.co/image/ab67616d0000b273497e68476e7062b1bf5ea83f"
  },
  {
    albumName: "good kid, m.A.A.d city (Deluxe)",
    albumArtists: "Kendrick Lamar",
    albumImage: "https://i.scdn.co/image/ab67616d0000b273d58e537cea05c2156792c53d"
  },
  {
    albumName: "The Forever Story",
    albumArtists: "JID",
    albumImage: "https://i.scdn.co/image/ab67616d0000b2736de37e432e720323f4e31edc"
  },
  {
    albumName: "Pray for Paris",
    albumArtists: "Westside Gunn",
    albumImage: "https://i.scdn.co/image/ab67616d0000b273a29bd588f4caf84bd4cd6f8b"
  },
];

export default function App() {
  const [currAlbumIndex, setCurrAlbumIndex] = useState(0);
  const [currAlbum, setCurrAlbum] = useState(albums[0]);
  const [previousAlbum, setPreviousAlbum] = useState(null);
  const [nextAlbum, setNextAlbum] = useState(albums[1]);

  const changeToPreviousAlbum = () => {
    if (currAlbumIndex >= 1) {
      setCurrAlbumIndex(state => state - 1);
    }
  };

  const changeToNextAlbum = () => {
    if (currAlbumIndex < albums.length - 1) {
      setCurrAlbumIndex(state => state + 1);
    }
  };

  useEffect(() => {
    setCurrAlbum(albums[currAlbumIndex]);

    if (currAlbumIndex >= 1) {
      setPreviousAlbum(albums[currAlbumIndex - 1]);
    } else {
      setPreviousAlbum(null);
    }

    if (currAlbumIndex < albums.length - 1) {
      setNextAlbum(albums[currAlbumIndex + 1]);
    } else {
      setNextAlbum(null);
    }
    
  }, [currAlbumIndex]);

  useEffect(() => {
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const interBubble = document.querySelector(".interactive");

    if (interBubble) {
      function move() {
        curX += (tgX - curX) / 45;
        curY += (tgY - curY) / 45;
        interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(move);
      }

      window.addEventListener("mousemove", (event) => {
        tgX = event.clientX;
        tgY = event.clientY;
      });

      move();
    }
  }, []);

  return (
    <div>
      {/* Background Gradients */}
      <div className="gradient-bg">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 18 -8" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
        <div className="gradients-container">
          <div className="g7"></div>
          <div className="g2"></div>
          <div className="g1"></div>
          <div className="g4"></div>
          <div className="g5"></div>
          <div className="g2"></div>
          <div className="g1"></div>
          <div className="g5"></div>
          <div className="g6"></div>
          <div className="g3"></div>
          <div className="interactive"></div>
        </div>
      </div>

      {/* Albums */}
      <AlbumDisplay
        album={currAlbum}
        previousAlbum={previousAlbum}
        nextAlbum={nextAlbum}
        changeToPreviousAlbum={changeToPreviousAlbum}
        changeToNextAlbum={changeToNextAlbum}
        isPreviousDisabled={currAlbumIndex < 1}
        isNextDisabled={currAlbumIndex >= albums.length - 1}
      />
    </div>
  );
}
